import { Avatar, Card, CardBody, Flex, Text } from "@chakra-ui/react";

interface DonutCardProps {
  avatar: string;
  name: string;
  price: string;
}

export const DonutCard = ({ avatar, name, price }: DonutCardProps) => {
  return (
    <Card>
      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap={4}>
            <Avatar size="sm" name={name} src={avatar} />
            <Text>{name}</Text>
          </Flex>
          <Text>{`${price} ₽`}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
};
