import { Box, Flex, Heading } from "@chakra-ui/react";
import { DonutCard } from "./DonutCard";

const Donuts = [
  {
    avatar: "https://bit.ly/dan-abramov",
    price: "10 000",
    name: "Dan Abramov",
  },
  {
    avatar:
      "https://vmrucdn.servicecdn.ru/2022.10/original/634d1ff482682c3a4aeeaed3.jpg",
    price: "2000",
    name: "Путин",
  },
  {
    avatar: "https://bit.ly/dan-abramov",
    price: "100",
    name: "Dan Abramov",
  },
  {
    avatar: "https://bit.ly/dan-abramov",
    price: "10 000",
    name: "Dan Abramov",
  },
  {
    avatar:
      "https://vmrucdn.servicecdn.ru/2022.10/original/634d1ff482682c3a4aeeaed3.jpg",
    price: "2000",
    name: "Путин",
  },
  {
    avatar: "https://bit.ly/dan-abramov",
    price: "100",
    name: "Dan Abramov",
  },
  {
    avatar: "https://bit.ly/dan-abramov",
    price: "100",
    name: "Dan Abramov",
  },
];

export const Table = () => {
  return (
    <Box mt={7}>
      <Heading size="md" mb={4}>
        Недавние донаты
      </Heading>
      <Flex flexDirection="column" gap={2}>
        {Donuts.map((item) => (
          <DonutCard name={item.name} avatar={item.avatar} price={item.price} />
        ))}
      </Flex>
    </Box>
  );
};
