import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AboutModal = ({ isOpen, onClose }: AboutModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} colorScheme="purple" size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>О нас</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={14}>
            Добро пожаловать на наш удивительный мир водных приключений! Мы -
            команда энтузиастов, которые разделяют страсть к рыбной индустрии и
            хотят поделиться этой страстью с вами. Наш сайт - это место, где вы
            можете погрузиться в удивительную и плавную жизнь рыб в реальном
            времени, наслаждаясь трансляциями из наших аквариумов.
          </Text>
          <Heading size="md" mt={2}>
            Что мы делаем?
          </Heading>
          <Text fontSize={14}>
            Мы предлагаем уникальную возможность следить за жизнью рыб в наших
            аквариумах, словно вы сами находитесь под водой. Вы сможете
            наблюдать за разнообразными видами рыб, их интересным поведением и
            узнать больше о том, как они живут. Наши камеры работают
            круглосуточно, чтобы вы могли наслаждаться этим зрелищем в любое
            время.
          </Text>
          <Heading size="md" mt={2}>
            Поддержите нас
          </Heading>
          <Text fontSize={14}>
            Мы работаем с огромным энтузиазмом и страстью к рыбам, чтобы
            делиться этой красотой с вами. Если вас вдохновляет то, что мы
            делаем, и вы хотите поддержать нас в нашем стремлении, у вас есть
            возможность задонатить. Ваши пожертвования помогут нам улучшить
            оборудование, увеличить количество аквариумов и расширить наши
            возможности для вас, наших зрителей.
          </Text>
          <Text fontSize={14} mt={2}>
            Благодарим вас за вашу поддержку и за то, что делаете наш проект
            возможным. Мы надеемся, что наш сайт приносит вам радость и
            вдохновение, а также способствует сохранению и популяризации
            великолепного мира рыб.
          </Text>
        </ModalBody>

        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
