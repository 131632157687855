import { Table } from "../components/Table";
import { Translation } from "../components/Translation";

export const MainPage = () => {
  return (
    <>
      <Translation />
      <Table />
    </>
  );
};
