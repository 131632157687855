import { Box, Container } from "@chakra-ui/react";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <Box pb={5}>
      <Header />
      <Container maxW="container.lg">
        <Outlet />
      </Container>
    </Box>
  );
};
