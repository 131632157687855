import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import logo from "../assets/Logo.svg";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { AboutModal } from "./AboutModal";
import { useEffect, useState } from "react";

export const Header = () => {
  interface tgApi {
    auth_date: number;
    username: string;
    hash: string;
    id: number;
    photo_url: string;
    first_name: string;
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profile, setProfile] = useState<tgApi>();

  const tgAuth = (e: any) => {
    // fetch(`${APIURL}/api/user.tg.login`,{
    //   method: 'POST',
    //   headers:{'content-type':'application/json'},
    //   body:JSON.stringify(e)
    // })
    // .then(e=>e.json())
    // .then(e=>{/* ... */})
    // .catch(()=>{})
    setProfile(e);
  };

  useEffect(() => {
    console.log(profile);
  }, [profile]);
  return (
    <>
      <Box borderBottom="1px solid #DDE1E6" mb={12} py={5}>
        <Container maxW="container.lg">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap={7}>
              <Image src={logo} />
              <Text
                cursor="pointer"
                _hover={{ color: "purple" }}
                transition=".3s"
                fontSize={16}
                onClick={onOpen}
              >
                О нас
              </Text>
            </Flex>
            <Flex alignItems="center" w="132px" justifyContent="space-between">
              <ColorModeSwitcher />
              {profile ? (
                <Menu>
                  <MenuButton>
                    <Avatar
                      size="md"
                      name={profile?.first_name}
                      src={profile?.photo_url}
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuGroup
                      title={profile.first_name}
                      fontWeight={400}
                      fontSize={16}
                    >
                      <MenuGroup title={`@${profile.username}`} fontSize={16}>
                        <MenuItem onClick={() => setProfile(undefined)}>
                          Выйти
                        </MenuItem>
                      </MenuGroup>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              ) : (
                <Button
                  colorScheme="purple"
                  size="sm"
                  onClick={() =>
                    // @ts-ignore
                    window.Telegram.Login.auth({ bot_id: 6743055068 }, tgAuth)
                  }
                >
                  Войти
                </Button>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
      <AboutModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
